import { GetStaticProps, NextPage } from 'next';

import api from 'api';
import ErrorCode from 'components/views/ErrorCode';
import { FooterMenuItem, NavbarMenuItem } from 'types/Menu';

type Props = {
  footerMenu: FooterMenuItem[];
  navbarMenu: NavbarMenuItem[];
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const { sanity } = api();
  const [navbarMenu, footerMenu] = await Promise.all([sanity.navbarMenu(), sanity.footerMenu()]);

  return {
    props: {
      footerMenu,
      navbarMenu,
    },
    revalidate: 600,
  };
};

const Page: NextPage<Props> = () => {
  return <ErrorCode code={404} detail="Sidan kunde inte hittas" />;
};

export default Page;
