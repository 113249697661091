import React from 'react';

import Typography from 'components/ui/Typography';
import classes from './ErrorCode.module.css';

export type ErrorCodeProps = {
  code: number;
  detail?: string;
};

const ErrorCode: React.FC<ErrorCodeProps> = props => {
  return (
    <div className={classes.container}>
      <Typography component="h1" variant="h1">
        {props.code}
      </Typography>
      {typeof props.detail === 'string' ? (
        <Typography component="p" variant="p">
          {props.detail}
        </Typography>
      ) : null}
    </div>
  );
};

export default ErrorCode;
